/* Template: Organ Donation Prototype
   Created: January 2022
   Description: Master CSS file
*/

/*****************************************
Table Of Contents:

01. General Styles
02. Navigation
03. Header
04. Forms
05. Tables View
06. Alerts
07. Preloader
******************************************/


/**************************/
/*  01. General Styles    */
/**************************/

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
  color: #fff;
}


html {
  /* background-color: #94e1ff; */
}

body {
  background: #94e1ff !important;
  font-family: "Montserrat", sans-serif !important;
}

main {
  margin-bottom: 1rem;
}

p {
  color: #fff;
  margin-bottom: 0rem !important;
}

h1 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

h2 {
  color: white;
}

h3 {
  color: #005f82;
}

.container{
  margin-bottom: 50px;
  width: 100%;
}

/**************************/
/*     02. Navigation     */
/**************************/

#header {
  overflow: visible;
  position: relative;
}

.row.center-box {
  justify-content: center;
  min-width: 50%;
}

.row .row-2 {
  height: fit-content;
}

nav {
  margin-top: 20px;
  background-color: #94e1ff;
  height: fit-content;
  width: 100%;
}

.nav-container {
  background-color: #94e1ff;
  height: fit-content;
  width: 100%;
}

nav ul {
  float: right;
  margin-right: 50px;
}

nav ul li {
  display: inline-block;
  line-height: 100px;
  margin: 0 5px;
}

nav ul li a {
  color: #005f82;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 12px;
  border-radius: 4px;

}

a.active,
a:hover {
  /* background: #fff; */
  transition: .5s;
  text-decoration: none;
}

a.hover:hover {
  background: none;
  /* padding: 0px; */
}


img.nav-logo {
  height: 70px;
  padding: 20px 0px 0px 50px;
}

/*********************/
/*    03. Header     */
/*********************/

.left-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.transplant-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 310px;
  background: #005f82;
  padding: 20px;
  border-radius: 12px;
  color: #fff;
}

.search .details {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  width: 225px;
}

#searchDonorCheck {
  padding: 8px;
  text-align: center;
}

#searchPatientCheck {
  padding: 8px;
  text-align: center;
}

.search h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.col-md-4.container-1 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.col-md-4.container-2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.left-content.width {
  display: block;
  width: 100%;
}

.right-content.width {
  display: block;
  width: 100%;
}

.btn-primary.custom {
  display: block;
  width: 100%;
  background-color: #005f82;
  border: none;
  font-size: 1.5em;
  font-weight: 600;
  padding: 20px;
  margin: 3px;
}

.btn-primary.custom.custom-tm {
  display: block;
  width: 100%;
}

.btn-primary.custom:hover {
  background-color: #004964;
}

.btn-primary.search {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.btn-primary.homepage {
  background-color: #005f82;
  border: none;
}

.btn-primary.homepage:hover {
  background-color: #004964;
}

/*********************/
/*   04. Forms       */
/*********************/

.col-md-6.form-bg {
  color: #fff;
  background-color: #005f82;
  border-radius: 8px;
  padding: 25px 40px;
  padding-top: 40px;
}

.col-md-4 p {
  font-weight: 600;
}

.row h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005f82;
  font-weight: 700;
  padding: 10px;
}

input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

input[type="text-home"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.btn-primary.register {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

#register {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  padding-top: 20px;
}



#group label {
  cursor: pointer;
}

#group label span {
  position: relative;
  display: inline-block;
  background-color: #004964;
  color: #fff;
  margin: 5px 2px;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s;
  user-select: none;
  overflow: hidden;
}

#group label span::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, );
}

#group input[type="checkbox"] {
  display: none;
}

#group input[type="checkbox"]:checked~span {
  color: #005f82;
  background: #fff;
}

#group input[type="radio"] {
  display: none;
}

#group input[type="radio"]:checked~span {
  color: #005f82;
  background: #fff;
}

/* .btn-warning.btn-1{
  font-size: 24px;
  color: #fff;
  background-color: #0079a8;
  border-color: #ffffff00;
  position: relative;
  border-radius: 8px;
  transform: translateX(350%);
  padding: 15px 15px 0px 15px ;
  margin: 0;
}

.btn-warning.btn-1:hover{
  color: #fff;
  background-color: #005f82;
}


.btn:hover {
  color: #212529;
  text-decoration: none;
}
 */
/* .custom-control-input:disabled ~ .custom-control-label {
  color: #ffffff;
} */

/*********************/
/*  05. Table view   */
/*********************/

.content-table {
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: center;
  border: 1px solid black;
  padding: 15px;
  font-weight: 600;
}

th {
  background-color: #005f82;
  border: 1px solid black;
  color: #ffffff;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

tr {
  background-color: #ffffff;
  color: black;
  text-align: center;
}

thead tr {
  background-color: #005f82;
  color: black;
  text-align: center;
}

tr:hover {
  background-color: rgb(212, 212, 212);
  color: #000000;
}

thead tr:hover {
  background-color: #005f82;
  color: #000000;
}

.table-responsive {
  border-radius: 8px;
  overflow-x: auto;
}

#pending-table-message {
  text-align: center;
}

#PledgeMessage {
  text-align: center;
}

.verification {
  width: 100%;
  background-color: #005f82;
  border-radius: 8.5px;
  padding: 25px;
  margin: 10px 0px;
}

.verification h2 {
  text-align: center;
  font-weight: 600;
}

.verification.hidden {
  visibility: hidden;
}

/*********************/
/*    06. Alerts     */
/*********************/

#DonorInputCheck {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#PatientInputCheck {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#PledgeInputCheck {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  width: 100%;
  padding: 10px;
  margin: 10px;
  opacity: 1;
  transition: opacity 0.6s;
}

.alert.warning {
  /* padding: 1px; */
  font-weight: 600;
  background: #e69100;
  display: none;
}

.closebtn {
  color: white;
  float: right;
  transform: translateY(-100%);
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

/*************************/
/*     07. Preloader     */
/*************************/

.spinner {
  position: fixed;
}

.spinner>div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #0079a8;
  text-align: center;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.custom-btn {
  background-color: #94e1ff;
  border: 2px solid #005f82;
  color: #005f82;
  font-size: larger;
  font-weight: 600;
  transition: all 0.3s ease;
}

.custom-btn:hover {
  background-color: #005f82;
  color: white;
}

.submit-btn {
  background-color: #004964;
  border: 3px solid #005f82;
  border-radius: 10px;
  color: #ffffff;
  font-size: larger;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 8px 22px;
}

.submit-btn:hover {
  background-color: #005f82;
  border: 3px solid #004964;
  color: white;
}

.thead-dark {
  background: #005f82;
}


